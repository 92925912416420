import { render, staticRenderFns } from "./G01Detail1TenderStat.vue?vue&type=template&id=37878c8c&scoped=true&"
import script from "./G01Detail1TenderStat.vue?vue&type=script&lang=js&"
export * from "./G01Detail1TenderStat.vue?vue&type=script&lang=js&"
import style0 from "./G01Detail1TenderStat.vue?vue&type=style&index=0&id=37878c8c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37878c8c",
  null
  
)

export default component.exports